import {
    REFRESHTABLE_USERPROFILE,
    SETDATA_USERPROFILE,
    SETORGANIZATION_USERPROFILE,
    SETDEPARTMENT_USERPROFILE,
    SETPOSITION_USERPROFILE,
    SETROLE_USERPROFILE,
    DIALOG_USERPROFILE_ADD_CLEARFIELD,
    DIALOG_USERPROFILE_ADD,
    DIALOG_USERPROFILE_ADD_LOADING,
    DIALOG_USERPROFILE_EDIT_CLEARFIELD,
    DIALOG_USERPROFILE_EDIT,
    DIALOG_USERPROFILE_EDIT_LOADING,
    DIALOG_USERPROFILE_DELETE,
    DIALOG_USERPROFILE_DELETE_LOADING,
    DIALOG_USERPROFILE_CHANGEPASSWORD_CLEARFIELD,
    DIALOG_USERPROFILE_CHANGEPASSWORD,
    DIALOG_USERPROFILE_CHANGEPASSWORD_LOADING
} from "../constants/userprofileConstants";

const initialState = {
    refreshtable: false,
    data: [],
    organization: [],
    department: [],
    position: [],
    role: [],
    dialogadd: {
        claerfield: false,
        open: false,
        loading: false,
    },
    dialogedit: {
        claerfield: false,
        open: false,
        loading: false,
        data: null
    },
    dialogdelete: {
        open: false,
        loading: false,
        data: null
    },
    dialogchangepassword: {
        claerfield: false,
        open: false,
        loading: false,
        data: null
    }
};

const userprofile = (state = initialState, action) => {
    switch (action.type) {
        case REFRESHTABLE_USERPROFILE:
            let data = {}
            if (action.payload) {
                data = {
                    dialogadd: {
                        open: false,
                        loading: false
                    },
                    dialogedit: {
                        open: false,
                        loading: false,
                        data: null
                    },
                    dialogdelete: {
                        open: false,
                        loading: false,
                        data: null
                    },
                    dialogchangepassword: {
                        open: false,
                        loading: false,
                        data: null
                    },
                }
            };
            return {
                ...state,
                ...data,
                refreshtable: action.payload,
            };
        case SETDATA_USERPROFILE:
            return {
                ...state,
                data: action.payload,
            };
        case SETORGANIZATION_USERPROFILE:
            return {
                ...state,
                organization: action.payload,
            };
        case SETDEPARTMENT_USERPROFILE:
            return {
                ...state,
                department: action.payload,
            };
        case SETPOSITION_USERPROFILE:
            return {
                ...state,
                position: action.payload,
            };
        case SETROLE_USERPROFILE:
            return {
                ...state,
                role: action.payload,
            };
        case DIALOG_USERPROFILE_ADD_CLEARFIELD:
            return {
                ...state,
                dialogadd: {
                    ...state.dialogadd,
                    claerfield: action.payload,
                }
            }
        case DIALOG_USERPROFILE_ADD:
            return {
                ...state,
                dialogadd: action.payload,
            }
        case DIALOG_USERPROFILE_ADD_LOADING:
            return {
                ...state,
                dialogadd: {
                    ...state.dialogadd,
                    loading: action.payload,
                }
            };
        case DIALOG_USERPROFILE_EDIT_CLEARFIELD:
            return {
                ...state,
                dialogedit: {
                    ...state.dialogedit,
                    claerfield: action.payload,
                }
            }
        case DIALOG_USERPROFILE_EDIT:
            return {
                ...state,
                department: [],
                position: [],
                dialogedit: action.payload
            }
        case DIALOG_USERPROFILE_EDIT_LOADING:
            return {
                ...state,
                dialogedit: {
                    ...state.dialogedit,
                    loading: action.payload,
                }
            };
        case DIALOG_USERPROFILE_DELETE:
            return {
                ...state,
                dialogdelete: action.payload
            }
        case DIALOG_USERPROFILE_DELETE_LOADING:
            return {
                ...state,
                dialogdelete: {
                    ...state.dialogdelete,
                    loading: action.payload,
                }
            };
        case DIALOG_USERPROFILE_CHANGEPASSWORD_CLEARFIELD:
            return {
                ...state,
                dialogchangepassword: {
                    ...state.dialogchangepassword,
                    claerfield: action.payload,
                }
            }
        case DIALOG_USERPROFILE_CHANGEPASSWORD:
            return {
                ...state,
                dialogchangepassword: action.payload
            }
        case DIALOG_USERPROFILE_CHANGEPASSWORD_LOADING:
            return {
                ...state,
                dialogchangepassword: {
                    ...state.dialogchangepassword,
                    loading: action.payload,
                }
            };
        default:
            return state;
    }
};

export default userprofile;