import { lazy } from "react";
const NotFound = lazy(() => import("./components/NotFound"));

export const routes = [   
    {
        path: "*",
        exact: true,
        component: NotFound,
        name: "notfound"
    }    
];