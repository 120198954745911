export const DIALOG_CLEARDEFAULT_MENUFROMEIOS = "DIALOG_CLEARDEFAULT_MENUFROMEIOS";
export const REFRESHTABLE_MENUFROMEIOS = "REFRESHTABLE_MENUFROMEIOS";
export const REFRESHTABLECATEGORY_MENUFROMEIOS = "REFRESHTABLECATEGORY_MENUFROMEIOS";
export const REFRESHTABLESIZE_MENUFROMEIOS = "REFRESHTABLESIZE_MENUFROMEIOS";
export const REFRESHTABLECRUST_MENUFROMEIOS = "REFRESHTABLECRUST_MENUFROMEIOS";
export const REFRESHTABLEPRODUCT_MENUFROMEIOS = "REFRESHTABLEPRODUCT_MENUFROMEIOS";
export const REFRESHTABLEPROMOTION_MENUFROMEIOS = "REFRESHTABLEPROMOTION_MENUFROMEIOS";
export const REFRESHTABLEINGREDIENT_MENUFROMEIOS = "REFRESHTABLEINGREDIENT_MENUFROMEIOS";
export const REFRESHTABLETIER_MENUFROMEIOS = "REFRESHTABLETIER_MENUFROMEIOS";
export const SETDATA_MENUFROMEIOS = "SETDATA_MENUFROMEIOS";
export const SETMENULIST_MENUFROMEIOS = "SETMENULIST_MENUFROMEIOS";
export const SETDATACATEGORY_MENUFROMEIOS = "SETDATACATEGORY_MENUFROMEIOS";
export const SETDATASIZE_MENUFROMEIOS = "SETDATASIZE_MENUFROMEIOS";
export const SETDATACRUST_MENUFROMEIOS = "SETDATACRUST_MENUFROMEIOS";
export const SETDATAPRODUCT_MENUFROMEIOS = "SETDATAPRODUCT_MENUFROMEIOS";
export const SETDATAPROMOTION_MENUFROMEIOS = "SETDATAPROMOTION_MENUFROMEIOS";
export const SETDATAINGREDIENT_MENUFROMEIOS = "SETDATAINGREDIENT_MENUFROMEIOS";
export const SETDATATIER_MENUFROMEIOS = "SETDATATIER_MENUFROMEIOS";
export const DIALOG_MENUFROMEIOS_ADD_CLEARFIELD = "DIALOG_MENUFROMEIOS_ADD_CLEARFIELD";
export const DIALOG_MENUFROMEIOS_ADD = "DIALOG_MENUFROMEIOS_ADD";
export const DIALOG_MENUFROMEIOS_ADD_LOADING = "DIALOG_MENUFROMEIOS_ADD_LOADING";
export const DIALOG_MENUFROMEIOS_EDIT_CLEARFIELD = "DIALOG_COUPONTYPE_EDIT_CLEARFIELD";
export const DIALOG_MENUFROMEIOS_EDIT = "DIALOG_MENUFROMEIOS_EDIT";
export const DIALOG_MENUFROMEIOS_EDIT_LOADING = "DIALOG_MENUFROMEIOS_EDIT_LOADING";
export const DIALOG_MENUFROMEIOS_EDIT_CATEGORY_LOADING = "DIALOG_MENUFROMEIOS_EDIT_CATEGORY_LOADING";
export const DIALOG_MENUFROMEIOS_EDIT_SIZE_LOADING = "DIALOG_MENUFROMEIOS_EDIT_SIZE_LOADING";
export const DIALOG_MENUFROMEIOS_EDIT_CRUST_LOADING = "DIALOG_MENUFROMEIOS_EDIT_CRUST_LOADING";
export const DIALOG_MENUFROMEIOS_EDIT_PRODUCT_LOADING = "DIALOG_MENUFROMEIOS_EDIT_PRODUCT_LOADING";
export const DIALOG_MENUFROMEIOS_EDIT_PROMOTION_LOADING = "DIALOG_MENUFROMEIOS_EDIT_PROMOTION_LOADING";
export const DIALOG_MENUFROMEIOS_EDIT_INGREDIENT_LOADING = "DIALOG_MENUFROMEIOS_EDIT_INGREDIENT_LOADING";
export const DIALOG_MENUFROMEIOS_EDIT_TIER_LOADING = "DIALOG_MENUFROMEIOS_EDIT_TIER_LOADING";
export const DIALOG_MENUFROMEIOS_DELETE = "DIALOG_MENUFROMEIOS_DELETE";
export const DIALOG_MENUFROMEIOS_DELETE_LOADING = "DIALOG_MENUFROMEIOS_DELETE_LOADING";