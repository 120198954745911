export const REFRESHTABLE_USERPROFILE = "REFRESHTABLE_USERPROFILE";
export const SETDATA_USERPROFILE = "SETDATA_USERPROFILE";
export const SETORGANIZATION_USERPROFILE = "SETORGANIZATION_USERPROFILE";
export const SETDEPARTMENT_USERPROFILE = "SETDEPARTMENT_USERPROFILE";
export const SETPOSITION_USERPROFILE = "SETPOSITION_USERPROFILE";
export const SETROLE_USERPROFILE = "SETROLE_USERPROFILE";
export const DIALOG_USERPROFILE_ADD_CLEARFIELD = "DIALOG_USERPROFILE_ADD_CLEARFIELD";
export const DIALOG_USERPROFILE_ADD = "DIALOG_USERPROFILE_ADD";
export const DIALOG_USERPROFILE_ADD_LOADING = "DIALOG_USERPROFILE_ADD_LOADING";
export const DIALOG_USERPROFILE_EDIT_CLEARFIELD = "DIALOG_COUPONTYPE_EDIT_CLEARFIELD";
export const DIALOG_USERPROFILE_EDIT = "DIALOG_COUPONTYPE_EDIT";
export const DIALOG_USERPROFILE_EDIT_LOADING = "DIALOG_USERPROFILE_EDIT_LOADING";
export const DIALOG_USERPROFILE_DELETE = "DIALOG_USERPROFILE_DELETE";
export const DIALOG_USERPROFILE_DELETE_LOADING = "DIALOG_USERPROFILE_DELETE_LOADING";
export const DIALOG_USERPROFILE_CHANGEPASSWORD_CLEARFIELD = "DIALOG_USERPROFILE_CHANGEPASSWORD_CLEARFIELD";
export const DIALOG_USERPROFILE_CHANGEPASSWORD = "DIALOG_USERPROFILE_CHANGEPASSWORD";
export const DIALOG_USERPROFILE_CHANGEPASSWORD_LOADING = "DIALOG_USERPROFILE_CHANGEPASSWORD_LOADING";