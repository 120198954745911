import {
    REFRESHTABLE_BLACKLIST,
    SETBLACKLIST_TYPE_LIST,
    SETBLACKLIST,
    DIALOG_BLACKLIST_ADD_CLEARFIELD,
    DIALOG_BLACKLIST_ADD,
    DIALOG_BLACKLIST_ADD_LOADING,
    DIALOG_BLACKLIST_EDIT_CLEARFIELD,
    DIALOG_BLACKLIST_EDIT,
    DIALOG_BLACKLIST_EDIT_LOADING,
    DIALOG_BLACKLIST_DELETE,
    DIALOG_BLACKLIST_DELETE_LOADING
} from "../constants/blacklistConstants";
import _ from "lodash";

const initialState = {
    refreshtable: false,
    blacklisttypelist: [],
    data: [],
    dialogadd: {
        claerfield: false,
        open: false,
        loading: false,
    },
    dialogedit: {
        claerfield: false,
        open: false,
        loading: false,
        data: null
    },
    dialogdelete: {
        open: false,
        loading: false,
        data: null
    },
};

const blacklist = (state = initialState, action) => {
    switch (action.type) {
        case REFRESHTABLE_BLACKLIST:
            let dialogdata = {}
            if (action.payload) {
                dialogdata = {
                    dialogadd: {
                        open: false,
                        loading: false
                    },
                    dialogedit: {
                        open: false,
                        loading: false,
                        data: null
                    },
                    dialogdelete: {
                        open: false,
                        loading: false,
                        data: null
                    }
                }
            };
            return {
                ...state,
                ...dialogdata,
                refreshtable: action.payload,
            };
        case SETBLACKLIST_TYPE_LIST:
            const data = action.payload;
            return {
                ...state,
                blacklisttypelist: data,
            };
        case SETBLACKLIST:
            let dataBlacklist = state.data;
            if (_.find(state.data, { blacklist_type_id: action.payload.blacklist_type_id })) {
                //clear roles           
                _.remove(dataBlacklist, item => item.blacklist_type_id === action.payload.blacklist_type_id);
                //add new data             
                dataBlacklist = [...state.data, action.payload];
            } else {
                //add data               
                dataBlacklist = [...state.data, action.payload];
            }
            return {
                ...state,
                data: dataBlacklist
            };
        case DIALOG_BLACKLIST_ADD_CLEARFIELD:
            return {
                ...state,
                dialogadd: {
                    ...state.dialogadd,
                    claerfield: action.payload,
                }
            }
        case DIALOG_BLACKLIST_ADD:
            return {
                ...state,
                dialogadd: action.payload,
            }
        case DIALOG_BLACKLIST_ADD_LOADING:
            return {
                ...state,
                dialogadd: {
                    ...state.dialogadd,
                    loading: action.payload,
                }
            };
        case DIALOG_BLACKLIST_EDIT_CLEARFIELD:
            return {
                ...state,
                dialogedit: {
                    ...state.dialogedit,
                    claerfield: action.payload,
                }
            }
        case DIALOG_BLACKLIST_EDIT:
            return {
                ...state,
                dialogedit: action.payload
            }
        case DIALOG_BLACKLIST_EDIT_LOADING:
            return {
                ...state,
                dialogedit: {
                    ...state.dialogedit,
                    loading: action.payload,
                }
            };
        case DIALOG_BLACKLIST_DELETE:
            return {
                ...state,
                dialogdelete: action.payload
            }
        case DIALOG_BLACKLIST_DELETE_LOADING:
            return {
                ...state,
                dialogdelete: {
                    ...state.dialogdelete,
                    loading: action.payload,
                }
            };
        default:
            return state;
    }
};

export default blacklist;