import React, { Suspense } from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ThemeProviders } from "./common/providers/ThemeProvider";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { store, persistor } from "./store";
import { SnackbarProvider } from "notistack";

import Notifier from "./common/notifier/Notifier";
import AppRouter from "./AppRouter";
import "./common/providers/i18n";

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Suspense fallback={null}>
          <ThemeProviders>
            <SnackbarProvider
              maxSnack={3}
              autoHideDuration={5000}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              style={{ pointerEvents: "auto" }}
            >
              <Notifier />
              <Router>
                <AppRouter />
              </Router>
            </SnackbarProvider>
          </ThemeProviders>
        </Suspense>
      </PersistGate>
    </Provider>
  );
};

export default App;