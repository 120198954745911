import {
  LOADING,
  REDIRECT,
  OPEN_MENU,
  SELECT_MENU,
  SET_MENU,
  SET_TIMEOUT
} from "../constants/comonConstants";

const initialState = {
  loading: false,
  redirect: {
    status: false,
    path: "/",
  },
  timeout: false,
  menu: {
    open: false,
    selectname: "",
    selectactive: false,
    list: [],
  }
};

const common = (state = initialState, action) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case REDIRECT:
      return {
        ...state,
        redirect: action.payload,
      };
    case OPEN_MENU:
      return {
        ...state,
        menu: {
          ...state.menu,
          open: action.payload,
        },
      };
    case SELECT_MENU:
      return {
        ...state,
        menu: {
          ...state.menu,
          selectname: action.payload.selectname,
          selectactive: action.payload.selectactive,
        },
      };
    case SET_MENU:
      return {
        ...state,
        menu: {
          ...state.menu,
          list: action.payload,
        },
      };
    case SET_TIMEOUT:
      return {
        ...state,
        timeout: action.payload,
      };
    default:
      return state;
  }
};

export default common;