export const REFRESHTABLE_STOREPROFILE = "REFRESHTABLE_STOREPROFILE";
export const REFRESHTABLE_STOREPROFILE_AREA = "REFRESHTABLE_STOREPROFILE_AREA";
export const SETDATA_STOREPROFILE = "SETDATA_STOREPROFILE";
export const SETAREA_STOREPROFILE = "SETAREA_STOREPROFILE";
export const SETORGANIZATION_STOREPROFILE = "SETORGANIZATION_STOREPROFILE";
export const SETSTOREFORMAT_STOREPROFILE = "SETSTOREFORMAT_STOREPROFILE";
export const SETPROVINCE_STOREPROFILE = "SETPROVINCE_STOREPROFILE";
export const DIALOG_STOREPROFILE_SYNC_CLEARFIELD = "DIALOG_STOREPROFILE_SYNC_CLEARFIELD";
export const DIALOG_STOREPROFILE_SYNC = "DIALOG_STOREPROFILE_SYNC";
export const DIALOG_STOREPROFILE_SYNC_LOADING = "DIALOG_STOREPROFILE_SYNC_LOADING";
export const DIALOG_STOREPROFILE_EDIT_CLEARFIELD = "DIALOG_STOREPROFILE_EDIT_CLEARFIELD";
export const DIALOG_STOREPROFILE_EDIT = "DIALOG_STOREPROFILE_EDIT";
export const DIALOG_STOREPROFILE_EDIT_LOADING = "DIALOG_STOREPROFILE_EDIT_LOADING";
export const DIALOG_STOREPROFILE_DELETE = "DIALOG_STOREPROFILE_DELETE";
export const DIALOG_STOREPROFILE_DELETE_LOADING = "DIALOG_STOREPROFILE_DELETE_LOADING";
export const DIALOG_STOREPROFILE_ADD_AREA = "DIALOG_STOREPROFILE_ADD_AREA";
export const DIALOG_STOREPROFILE_ADD_AREA_LOADING = "DIALOG_STOREPROFILE_ADD_AREA_LOADING";
export const DIALOG_STOREPROFILE_ADD_AREA_CLEARFIELD = "DIALOG_STOREPROFILE_ADD_AREA_CLEARFIELD";