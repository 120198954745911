import { authHeader, baseUrl, handleResponse } from "../../helpers";
import axios from "axios";

const login = async (username, password) => {
  let res = {
    result: true,
    status: 200,
  };
  const headers = {
    "Content-Type": "application/json",
  };

  return await axios
    .post(
      `${baseUrl()}/api/auth/signin`,
      { username, password },
      {
        headers: headers,
      }
    )
    .then((response) => {      
      return { ...res, data: response.data, status: response.status };
    })
    .catch(handleResponse);
};


const logout = () => {
  // remove user from local storage to log user out
  localStorage.removeItem("user");
};

export const authService = {
  login,
  logout,
};