import { routes as home } from "./views/home";
import { routes as auth } from "./views/auth";
import { routes as callcenter } from "./views/callcenter";
import { routes as loyalty } from "./views/loyalty";
import { routes as settings } from "./views/settings";
import { routes as notfound } from "./views/notfound";

export const routes = [
    ...home,   
    ...loyalty, 
    ...callcenter,    
    ...settings,
    ...notfound
];

export const authRoutes = [
    ...auth,    
];