import React, { lazy, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Routes as Switch, Route } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";

import { routes } from "../../../routes";
import { Loading } from "../../loading";

import useWindowSize from "../../../utils/useWindowSize";
// import { MuiPickersUtilsProvider } from '@material-ui/pickers';
// import DateFnsUtils from '@date-io/date-fns';

// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import enLocale from "date-fns/locale/en-US";
import thLocale from "date-fns/locale/th";

import { useTranslation } from "react-i18next";
import { menuActions, pageActions } from "../../../actions/common";

const Header = lazy(() => import("./Header"));
const DrawerMenu = lazy(() => import("./DrawerMenu"));
const Timeout = lazy(() => import("./Timeout"));

const localeMap = {
  en: enLocale,
  th: thLocale
};

const Layout = () => {
  const { i18n } = useTranslation();
  const commonState = useSelector((state) => state.common);
  const dispatch = useDispatch();

  const { width } = useWindowSize();
  const mobile = width < 1200 ? true : false;

  const { getRemainingTime } = useIdleTimer({
    timeout: (1000 * 60 * Number(process.env.REACT_APP_TIMEOUT)),
    throttle: 500
  })

  //Time out
  React.useEffect(() => {
    const interval = setInterval(() => {
      const remaing = getRemainingTime();
      if (remaing == 0) {
        dispatch(pageActions.timeOut(true));
      }
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  })

  React.useEffect(() => {
    dispatch(menuActions.getDrawerMenu())
  }, []);

  // Refresh Token every 15 minutes
  React.useEffect(
    () => {
      const id = setInterval(() => {
        dispatch(pageActions.refreshToken());
      }, 1000 * 60 * 15);
      return () => {
        clearInterval(id);
      };
    },
    []
  );

  // <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[i18n.language]}>
  // </MuiPickersUtilsProvider>
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={localeMap[i18n.language]}>
        {commonState.timeout && <Timeout />}
        <Header />
        <DrawerMenu />
        <ContainerLayout openmenu={commonState.menu.open.toString()} mobile={mobile.toString()}>
          {commonState.loading && <Loading />}
          <Suspense fallback={<Loading />}>
            <Switch>
              {routes.map((route, idx) => {               
                return route.component ? (
                  <Route
                    key={idx}
                    path={route.path}
                    element={<route.component />}
                  />
                ) : null;
              })}
            </Switch>
          </Suspense>
        </ContainerLayout>
      </LocalizationProvider>
    </>
  );
};

const ContainerLayout = styled.div`
  padding-bottom: 20px;
  ${(props) =>
    (props.openmenu === "true" && props.mobile === "false")
      ? ` width: calc(100% - 250px);
    transition: margin 225ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,width 225ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    margin-left: 250px;`
      : ""}// transition: margin 225ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
// margin-left: 250px;
`;

export default Layout;