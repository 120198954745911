import {
    REFRESHTABLE_IMPORTCOUPONCODE,
    SETDATA_IMPORTCOUPONCODE,
    DIALOG_IMPORTCOUPONCODE_ADD_CLEARFIELD,
    DIALOG_IMPORTCOUPONCODE_ADD,
    DIALOG_IMPORTCOUPONCODE_ADD_LOADING,   
    DIALOG_IMPORTCOUPONCODE_EDIT_CLEARFIELD,
    DIALOG_IMPORTCOUPONCODE_EDIT,
    DIALOG_IMPORTCOUPONCODE_EDIT_LOADING,
    DIALOG_IMPORTCOUPONCODE_DELETE,
    DIALOG_IMPORTCOUPONCODE_DELETE_LOADING
} from "../constants/importcouponcodeConstants";

const initialState = {
    refreshtable: false,
    data: [],
    dialogadd: {
        claerfield: false,
        open: false,
        loading: false,
    },
    dialogaddmore: {
        claerfield: false,
        open: false,
        loading: false,
    },
    dialogedit: {
        claerfield: false,
        open: false,
        loading: false,
        data: null
    },
    dialogdelete: {
        open: false,
        loading: false,
        data: null
    },
};

const importcouponcode = (state = initialState, action) => {
    switch (action.type) {
        case REFRESHTABLE_IMPORTCOUPONCODE:
            let data = {}
            if (action.payload) {
                data = {
                    dialogadd: {
                        open: false,
                        loading: false
                    },
                    dialogaddmore: {
                        open: false,
                        loading: false
                    },
                    dialogedit: {
                        open: false,
                        loading: false,
                        data: null
                    },
                    dialogdelete: {
                        open: false,
                        loading: false,
                        data: null
                    }
                }
            };
            return {
                ...state,
                ...data,
                refreshtable: action.payload,
            };
        case SETDATA_IMPORTCOUPONCODE:
            return {
                ...state,
                data: action.payload,
            };
        case DIALOG_IMPORTCOUPONCODE_ADD_CLEARFIELD:
            return {
                ...state,
                dialogadd: {
                    ...state.dialogadd,
                    claerfield: action.payload,
                }
            }
        case DIALOG_IMPORTCOUPONCODE_ADD:
            return {
                ...state,
                dialogadd: action.payload,
            }
        case DIALOG_IMPORTCOUPONCODE_ADD_LOADING:           
            return {
                ...state,
                dialogadd: {
                    ...state.dialogadd,
                    loading: action.payload,
                }
            };        
        case DIALOG_IMPORTCOUPONCODE_EDIT_CLEARFIELD:
            return {
                ...state,
                dialogedit: {
                    ...state.dialogedit,
                    claerfield: action.payload,
                }
            }
        case DIALOG_IMPORTCOUPONCODE_EDIT:
            return {
                ...state,
                dialogedit: action.payload
            }
        case DIALOG_IMPORTCOUPONCODE_EDIT_LOADING:
            return {
                ...state,
                dialogedit: {
                    ...state.dialogedit,
                    loading: action.payload,
                }
            };
        case DIALOG_IMPORTCOUPONCODE_DELETE:
            return {
                ...state,
                dialogdelete: action.payload
            }
        case DIALOG_IMPORTCOUPONCODE_DELETE_LOADING:
            return {
                ...state,
                dialogdelete: {
                    ...state.dialogdelete,
                    loading: action.payload,
                }
            };
        default:
            return state;
    }
};

export default importcouponcode;