import {
    REFRESHTABLE_GENERATECOUPONCODE,
    SETDATA_GENERATECOUPONCODE,
    DIALOG_GENERATECOUPONCODE_ADD_CLEARFIELD,
    DIALOG_GENERATECOUPONCODE_ADD,
    DIALOG_GENERATECOUPONCODE_ADD_LOADING,
    DIALOG_GENERATECOUPONCODE_ADD_MORE_CLEARFIELD,
    DIALOG_GENERATECOUPONCODE_ADD_MORE,
    DIALOG_GENERATECOUPONCODE_ADD_MORE_LOADING,
    DIALOG_GENERATECOUPONCODE_EDIT_CLEARFIELD,
    DIALOG_GENERATECOUPONCODE_EDIT,
    DIALOG_GENERATECOUPONCODE_EDIT_LOADING,
    DIALOG_GENERATECOUPONCODE_DELETE,
    DIALOG_GENERATECOUPONCODE_DELETE_LOADING
} from "../constants/generatecouponcodeConstants";

const initialState = {
    refreshtable: false,
    data: [],
    dialogadd: {
        claerfield: false,
        open: false,
        loading: false,
    },
    dialogaddmore: {
        claerfield: false,
        open: false,
        loading: false,
    },
    dialogedit: {
        claerfield: false,
        open: false,
        loading: false,
        data: null
    },
    dialogdelete: {
        open: false,
        loading: false,
        data: null
    },
};

const generatecouponcode = (state = initialState, action) => {
    switch (action.type) {
        case REFRESHTABLE_GENERATECOUPONCODE:
            let data = {}
            if (action.payload) {
                data = {
                    dialogadd: {
                        open: false,
                        loading: false
                    },
                    dialogaddmore: {
                        open: false,
                        loading: false
                    },
                    dialogedit: {
                        open: false,
                        loading: false,
                        data: null
                    },
                    dialogdelete: {
                        open: false,
                        loading: false,
                        data: null
                    }
                }
            };
            return {
                ...state,
                ...data,
                refreshtable: action.payload,
            };
        case SETDATA_GENERATECOUPONCODE:
            return {
                ...state,
                data: action.payload,
            };
        case DIALOG_GENERATECOUPONCODE_ADD_CLEARFIELD:
            return {
                ...state,
                dialogadd: {
                    ...state.dialogadd,
                    claerfield: action.payload,
                }
            }
        case DIALOG_GENERATECOUPONCODE_ADD:
            return {
                ...state,
                dialogadd: action.payload,
            }
        case DIALOG_GENERATECOUPONCODE_ADD_LOADING:
            return {
                ...state,
                dialogadd: {
                    ...state.dialogadd,
                    loading: action.payload,
                }
            };
        case DIALOG_GENERATECOUPONCODE_ADD_MORE_CLEARFIELD:
            return {
                ...state,
                dialogaddmore: {
                    ...state.dialogaddmore,
                    claerfield: action.payload,
                }
            }
        case DIALOG_GENERATECOUPONCODE_ADD_MORE:          
            return {
                ...state,
                dialogaddmore: action.payload,
            }
        case DIALOG_GENERATECOUPONCODE_ADD_MORE_LOADING:
            return {
                ...state,
                dialogaddmore: {
                    ...state.dialogaddmore,
                    loading: action.payload,
                }
            };
        case DIALOG_GENERATECOUPONCODE_EDIT_CLEARFIELD:
            return {
                ...state,
                dialogedit: {
                    ...state.dialogedit,
                    claerfield: action.payload,
                }
            }
        case DIALOG_GENERATECOUPONCODE_EDIT:
            return {
                ...state,
                dialogedit: action.payload
            }
        case DIALOG_GENERATECOUPONCODE_EDIT_LOADING:
            return {
                ...state,
                dialogedit: {
                    ...state.dialogedit,
                    loading: action.payload,
                }
            };
        case DIALOG_GENERATECOUPONCODE_DELETE:
            return {
                ...state,
                dialogdelete: action.payload
            }
        case DIALOG_GENERATECOUPONCODE_DELETE_LOADING:
            return {
                ...state,
                dialogdelete: {
                    ...state.dialogdelete,
                    loading: action.payload,
                }
            };
        default:
            return state;
    }
};

export default generatecouponcode;