export const REFRESHTABLE_BLACKLIST = "REFRESHTABLE_BLACKLIST";
export const SETBLACKLIST_TYPE_LIST = "SETBLACKLIST_TYPE_LIST";
export const SETBLACKLIST = "SETBLACKLIST";

export const DIALOG_BLACKLIST_ADD_CLEARFIELD = "DIALOG_BLACKLIST_ADD_CLEARFIELD";
export const DIALOG_BLACKLIST_ADD = "DIALOG_BLACKLIST_ADD";
export const DIALOG_BLACKLIST_ADD_LOADING = "DIALOG_BLACKLIST_ADD_LOADING";
export const DIALOG_BLACKLIST_EDIT_CLEARFIELD = "DIALOG_BLACKLIST_EDIT_CLEARFIELD";
export const DIALOG_BLACKLIST_EDIT = "DIALOG_BLACKLIST_EDIT";
export const DIALOG_BLACKLIST_EDIT_LOADING = "DIALOG_BLACKLIST_EDIT_LOADING";
export const DIALOG_BLACKLIST_DELETE = "DIALOG_BLACKLIST_DELETE";
export const DIALOG_BLACKLIST_DELETE_LOADING = "DIALOG_BLACKLIST_DELETE_LOADING";