import {
    REFRESHTABLE_COUPONTYPE,
    SETDATA_COUPONTYPE,
    DIALOG_COUPONTYPE_ADD_CLEARFIELD,
    DIALOG_COUPONTYPE_ADD,
    DIALOG_COUPONTYPE_ADD_LOADING,
    DIALOG_COUPONTYPE_EDIT_CLEARFIELD,
    DIALOG_COUPONTYPE_EDIT,
    DIALOG_COUPONTYPE_EDIT_LOADING,
    DIALOG_COUPONTYPE_DELETE,
    DIALOG_COUPONTYPE_DELETE_LOADING
} from "../constants/coupontypeConstants";

const initialState = {
    refreshtable: false,
    data: [],
    dialogadd: {
        claerfield: false,
        open: false,
        loading: false,
    },
    dialogedit: {
        claerfield: false,
        open: false,
        loading: false,
        data: null
    },
    dialogdelete: {
        open: false,
        loading: false,
        data: null
    },
};

const coupontype = (state = initialState, action) => {
    switch (action.type) {
        case REFRESHTABLE_COUPONTYPE:
            let data = {}
            if (action.payload) {
                data = {
                    dialogadd: {
                        open: false,
                        loading: false
                    },
                    dialogedit: {
                        open: false,
                        loading: false,
                        data: null
                    },
                    dialogdelete: {
                        open: false,
                        loading: false,
                        data: null
                    }
                }
            };
            return {
                ...state,
                ...data,
                refreshtable: action.payload,
            };
        case SETDATA_COUPONTYPE:
            return {
                ...state,
                data: action.payload,
            };
        case DIALOG_COUPONTYPE_ADD_CLEARFIELD:
            return {
                ...state,
                dialogadd: {
                    ...state.dialogadd,
                    claerfield: action.payload,
                }
            }
        case DIALOG_COUPONTYPE_ADD:
            return {
                ...state,
                dialogadd: action.payload,
            }
        case DIALOG_COUPONTYPE_ADD_LOADING:
            return {
                ...state,
                dialogadd: {
                    ...state.dialogadd,
                    loading: action.payload,
                }
            };
        case DIALOG_COUPONTYPE_EDIT_CLEARFIELD:
                return {
                    ...state,
                    dialogedit: {
                        ...state.dialogedit,
                        claerfield: action.payload,
                    }
                }
        case DIALOG_COUPONTYPE_EDIT:
            return {
                ...state,
                dialogedit: action.payload
            }
        case DIALOG_COUPONTYPE_EDIT_LOADING:
            return {
                ...state,
                dialogedit: {
                    ...state.dialogedit,
                    loading: action.payload,
                }
            };
        case DIALOG_COUPONTYPE_DELETE:
            return {
                ...state,
                dialogdelete: action.payload
            }
        case DIALOG_COUPONTYPE_DELETE_LOADING:
            return {
                ...state,
                dialogdelete: {
                    ...state.dialogdelete,
                    loading: action.payload,
                }
            };
        default:
            return state;
    }
};

export default coupontype;