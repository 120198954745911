export function extractGoogleCoords(plainText) {
    let parser = new DOMParser()
    let xmlDoc = parser.parseFromString(plainText, "text/xml")
    let googlePolygons = []
    let googleMarkers = []

    const randomColor = Math.floor(Math.random()*16777215).toString(16);
    // if (xmlDoc.documentElement.nodeName == "kml") {

    try {
        for (const item of xmlDoc.getElementsByTagName('Placemark')) {
            let placeMarkName = item.getElementsByTagName('name')[0]?.childNodes[0]?.nodeValue.trim()
            let polygons = item.getElementsByTagName('Polygon')
            let markers = item.getElementsByTagName('Point')
            let extendeddata = item.getElementsByTagName('ExtendedData')
            let styles = item.getElementsByTagName('Style')

            let storename = '';
            let quadrantid = '';
            let quadrantname = '';
            let linecolor = '';
            let polygoncolor = '';

            /** EXTENDEDDATA PARSE **/
            for (const extened of extendeddata) {
                //For schema Data
                let data = extened.getElementsByTagName('Data')
                if (data.length > 0) {
                    for (const item of data) {
                        const name = item.getAttribute("name");
                        if (name == "Storename") {
                            storename = item.getElementsByTagName("value")[0].childNodes[0].nodeValue.trim();
                        }
                        if (name == "QuadrantId") {
                            quadrantid = item.getElementsByTagName("value")[0].childNodes[0].nodeValue.trim();
                        }
                        if (name == "QuadrantName") {
                            quadrantname = item.getElementsByTagName("value")[0].childNodes[0].nodeValue.trim();
                        }
                    }
                }
                //For schema SchemaData
                let schemadata = extened.getElementsByTagName('SchemaData')
                if (schemadata.length > 0) {
                    for (const item of schemadata) {                        
                        storename = item.querySelectorAll('[name="Storename"]')[0].childNodes[0].nodeValue.trim();
                        quadrantid = item.querySelectorAll('[name="QuadrantID"]')[0].childNodes[0].nodeValue.trim();
                        quadrantname = item.querySelectorAll('[name="GRID_ID"]')[0].childNodes[0].nodeValue.trim();                                               
                    }
                }
            }

            /** STYLE PARSE **/
            for (const style of styles) {
                const linestyles = style.getElementsByTagName('LineStyle')
                for (const linestyle of linestyles) {
                    linecolor = linestyle.getElementsByTagName('color')[0].childNodes[0].nodeValue.trim()
                }

                const polystyles = style.getElementsByTagName('PolyStyle')
                for (const polystyle of polystyles) {
                    polygoncolor = polystyle.getElementsByTagName('color')[0].childNodes[0].nodeValue.trim()
                }
            }

            /** POLYGONS PARSE **/
            for (const polygon of polygons) {
                let coords = polygon.getElementsByTagName('coordinates')[0].childNodes[0].nodeValue.trim()
                let points = coords.split(" ")


                let googlePolygonsPaths = [];

                //Define position of label
                const bounds = new window.google.maps.LatLngBounds();
                for (const point of points) {
                    let coord = point.split(",")
                    googlePolygonsPaths.push({ lat: +coord[1], lng: +coord[0] });

                    bounds.extend({ lat: +coord[1], lng: +coord[0] });
                }

                const centerLatlng = bounds.getCenter();

                //Get Area
                const polygonGrid = new window.google.maps.Polygon({
                    path: googlePolygonsPaths
                })
                const areaGrid = window.google.maps.geometry.spherical.computeArea(polygonGrid.getPath()) / 1000000;
             

                const prmPolygons = {
                    storename: storename,
                    quadrantid: quadrantid,
                    quadrantname: quadrantname,
                    linecolor: `#${linecolor.replace('#', '')}`,
                    // polygoncolor: `#${polygoncolor.replace('#', '')}`,
                    polygoncolor: `#${randomColor}`,
                    polygons: googlePolygonsPaths,
                    centercoordinates: centerLatlng,
                    area: areaGrid.toFixed(4)
                }              
                googlePolygons.push(prmPolygons)
            }

            /** MARKER PARSE **/
            for (const marker of markers) {
                var coords = marker.getElementsByTagName('coordinates')[0].childNodes[0].nodeValue.trim()
                let coord = coords.split(",")
                googleMarkers.push({ lat: +coord[1], lng: +coord[0] })
            }
        }
    } catch (e) {
        console.log(e)
    }

    return { markers: googleMarkers, polygons: googlePolygons }

}