import React, { Suspense } from "react";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import { createTheme, ThemeProvider as ThemeProviderUI } from '@mui/material/styles';
import { StyledEngineProvider} from "@mui/material";

import { lightTheme, darkTheme } from "./Theme";
import useDarkMode from "use-dark-mode";

export const ThemeProviders = (props) => {
  const darkMode = useDarkMode(true);
  const theme = darkMode.value ? darkTheme : lightTheme;

  const THEME = createTheme({
    typography: {
      //   fontFamily: Font,
      fontSize: 14,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
    },
  });

  const GlobalStyle = createGlobalStyle` 
  html::-webkit-scrollbar {
    display: none;
  }
  body {
    background-color: ${(props) => props.theme.body.bg};  
    color: ${(props) => props.theme.text.primary};    
    min-height: 100vh;
  }
  .Mui-disabled {
    cursor: no-drop;
  }

  //Date Picker
  .MuiCalendarPicker-root {
    color: ${(props) => props.theme.text.tertiary};
    background-color: ${(props) => props.theme.drawermenu.bg};
  }
  .MuiCalendarPicker-root div div button.MuiButtonBase-root.MuiIconButton-root {
    color: ${(props) => props.theme.text.tertiary};
  }
  .MuiTypography-root.MuiTypography-caption.MuiDayPicker-weekDayLabel {
    color: ${(props) => props.theme.text.tertiary};
  } 
  button.MuiButtonBase-root.Mui-disabled.MuiPickersDay-root.Mui-disabled.MuiPickersDay-dayWithMargin {
    color: ${(props) => props.theme.text.quarternary};
  }
  button.MuiButtonBase-root.MuiPickersDay-root.MuiPickersDay-dayWithMargin {
    color: ${(props) => props.theme.text.tertiary};
    background-color: ${(props) => props.theme.drawermenu.bg};
  }
  button.MuiButtonBase-root.MuiPickersDay-root.MuiPickersDay-dayWithMargin.MuiPickersDay-today {
    color: ${(props) => props.theme.text.secondary};
    border-color: ${(props) => props.theme.text.secondary};
  }
  button.MuiButtonBase-root.MuiPickersDay-root.Mui-selected.MuiPickersDay-dayWithMargin {
    color: #FFFFFF;
    background-color: #70A401;
    border: 1px solid #70A401;
  }
  .PrivatePickersYear-yearButton.Mui-selected {
    color: #FFFFFF;
    background-color: #70A401;
    border: 1px solid #70A401;
  }
  .PrivatePickersYear-yearButton.Mui-selected:focus, .PrivatePickersYear-yearButton.Mui-selected:hover {
    background-color: #8DB634;
  }







  .MuiPaper-root.MuiDialog-paper.MuiPickersModal-dialogRoot.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded {
    color: ${(props) => props.theme.text.tertiary};
    background-color: ${(props) => props.theme.drawermenu.bg};
  }
  button.MuiButtonBase-root.MuiIconButton-root.MuiPickersCalendarHeader-iconButton {
    background-color: ${(props) => props.theme.drawermenu.bg};
  }
  .MuiPickersToolbar-toolbar {    
    background-image: url("${(props) => props.theme.drawermenuimage.primary}"); 
    background-position: center; 
    background-repeat: no-repeat;
    background-size: cover; 
  }
  .MuiPickersCalendarHeader-switchHeader>.MuiIconButton-root {   
    color: ${(props) => props.theme.text.tertiary};  
  }
  .MuiPickersCalendarHeader-dayLabel {
    color: ${(props) => props.theme.text.quarternary};
  }
  .MuiPaper-root.MuiDialog-paper.MuiPickersModal-dialogRoot.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded>.MuiDialogActions-root.MuiDialogActions-spacing>button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary {
    color: ${(props) => props.theme.text.tertiary};
    font-weight: 600;

    &:disabled {
      color: ${(props) => props.theme.text.quarternary};  
      pointer-events: auto;
      cursor: no-drop;
    }
  }
  .MuiPaper-root.MuiDialog-paper.MuiPickersModal-dialogRoot.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded>.MuiDialogActions-root.MuiDialogActions-spacing>button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary:first-child {
    color: ${(props) => props.theme.text.quarternary};

    &:disabled {
      color: ${(props) => props.theme.text.quarternary};   
      pointer-events: auto;
      cursor: no-drop;
    }
  }

  .MuiPickersDay-day {
    color: ${(props) => props.theme.text.quarternary};  
  }
  .MuiPickersDay-dayDisabled {
    color: ${(props) => props.theme.textborder.secondary};
  }
  .MuiPickersDay-daySelected {  
    color: #ffff;  
    background-color: #70a401;
  }
  .MuiPickersDay-daySelected:hover {
    background-color: #8db634;
  }
  .MuiPickersDay-current {
    color: ${(props) => props.theme.text.primary};  
    font-weight: 600;
  }
  button.MuiButtonBase-root.MuiIconButton-root.MuiPickersDay-day.MuiPickersDay-current.MuiPickersDay-daySelected {
    color: #ffff;
  }
  ul.MuiList-root.MuiList-padding.MuiMenu-list {
    background-color: ${(props) => props.theme.section.bg};
    color: ${(props) => props.theme.text.secondary};
  }
`;

  return (
    <Suspense fallback={null}>
      <StyledEngineProvider injectFirst>
        <ThemeProviderUI theme={THEME} >
          <ThemeProvider theme={theme}>
            <GlobalStyle />
            {props.children}
          </ThemeProvider>
        </ThemeProviderUI>
      </StyledEngineProvider>
    </Suspense>
  );
};