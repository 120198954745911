const light = {
  header: {
    bg: "#ffffff",
    border: "#e4e4e4",
  },
  body: {
    bg: "#f7f9fc",
  },
  drawermenu: {
    bg: "#fff"
  },
  drawermenuimage: {
    primary: `${process.env.REACT_APP_BASEURL}/public/images/MenuLight.jpg`
  },
  loading: {
    bg: "#ffffff80",
  }, 
  section: {
    bg: "#fff",
    border: "#e4e4e4"
  },  
  divider: {
    primary: "#e4e4e4"
  },
  text: {
    primary: "#050505",
    secondary: "#2f3037",
    tertiary: "#525560",
    quarternary: "#9194a1",
    error: "#e13340"
  },
  svg: {
    primary: "brightness(0) invert(0.2)",
  },
  textborder: {
    primary: "#9e9e9e4a",
    secondary: "#504f4f4a",
    error: "#e13340",
  },
  option: {
    selected_bg: "#70a401",
    focused_bg: "#f7f9fc"
  },
  disable: {
    bg: "#0000001f",
    color: "#00000042"
  }
};

const dark = {
  header: {
    bg: "#252525",
    border: "#ffffff42",
  },
  body: {
    bg: "#383838",
  },
  drawermenu: {
    bg: "#2f2f2f",
  },
  drawermenuimage: {
    primary: `${process.env.REACT_APP_BASEURL}/public/images/MenuDark.jpg`
  },
  loading: {
    bg: "#000000a8",
  }, 
  section: {
    bg: "#2f2f2f",
    border: "#ffffff42"
  },
  divider: {
    primary: "#ffffff42"
  },
  text: {
    primary: "#fbfbfc",
    secondary: "#e3e4e8",
    tertiary: "#a9abb6",
    quarternary: "#868686",
    error: "#e13340"
  },
  svg: {
    primary: "brightness(0) invert(1)",
  },
  textborder: {
    primary: "#ffffff6e",
    secondary: "#ffffffb3",
    error: "#e13340",
  },
  option: {
    selected_bg: "#1b1b1b",
    focused_bg: "#383838"
  },
  disable: {
    bg: "#c7c7c759",
    color: "#ffffffad"
  }
};

const defaultTheme = {
  fontSizes: [
    "14px", // 0
    "16px", // 1
    "18px", // 2
    "22px", // 3
    "26px", // 4
    "32px", // 5
    "40px", // 6
  ],
  fontWeights: {
    body: 400,
    subheading: 500,
    link: 600,
    bold: 700,
    heading: 800,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.3,
    code: 1.6,
  },
  // ...
};

export const lightTheme = { ...defaultTheme, ...light };
export const darkTheme = { ...defaultTheme, ...dark };