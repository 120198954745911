export const REFRESHTABLE_GENERATECOUPONCODE = "REFRESHTABLE_GENERATECOUPONCODE";
export const SETDATA_GENERATECOUPONCODE = "SETDATA_GENERATECOUPONCODE";
export const DIALOG_GENERATECOUPONCODE_ADD_CLEARFIELD = "DIALOG_GENERATECOUPONCODE_ADD_CLEARFIELD";
export const DIALOG_GENERATECOUPONCODE_ADD = "DIALOG_GENERATECOUPONCODE_ADD";
export const DIALOG_GENERATECOUPONCODE_ADD_LOADING = "DIALOG_GENERATECOUPONCODE_ADD_LOADING";
export const DIALOG_GENERATECOUPONCODE_ADD_MORE_CLEARFIELD = "DIALOG_GENERATECOUPONCODE_ADD_MORE_CLEARFIELD";
export const DIALOG_GENERATECOUPONCODE_ADD_MORE = "DIALOG_GENERATECOUPONCODE_ADD_MORE";
export const DIALOG_GENERATECOUPONCODE_ADD_MORE_LOADING = "DIALOG_GENERATECOUPONCODE_ADD_MORE_LOADING";
export const DIALOG_GENERATECOUPONCODE_EDIT_CLEARFIELD = "DIALOG_GENERATECOUPONCODE_EDIT_CLEARFIELD";
export const DIALOG_GENERATECOUPONCODE_EDIT = "DIALOG_GENERATECOUPONCODE_EDIT";
export const DIALOG_GENERATECOUPONCODE_EDIT_LOADING = "DIALOG_GENERATECOUPONCODE_EDIT_LOADING";
export const DIALOG_GENERATECOUPONCODE_DELETE = "DIALOG_GENERATECOUPONCODE_DELETE";
export const DIALOG_GENERATECOUPONCODE_DELETE_LOADING = "DIALOG_GENERATECOUPONCODE_DELETE_LOADING";