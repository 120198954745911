import authReducer from './reducers/auth.reducer';
import userReducer from './reducers/user.reducer';
import roleReducer from './reducers/role.reducer';
import commonReducer from './reducers/common.reducer';
import notifierReducer from './reducers/notifier.reduces';
import statusReducer from './reducers/status.reducer';
import lineoarichmenuReducer from './reducers/lineoa/richmenu.reducer';
import validatecodeReducer from './reducers/validatecode.reducer';
import coupontypeReducer from './reducers/coupontype.reducer';
import generatecouponcodeReducer from './reducers/generatecouponcode.reducer';
import importcouponcodeReducer from './reducers/importcouponcode.reducer';
import blacklistReducer from './reducers/blacklist.reducer';
import userprofileReducer from './reducers/userprofile.reducer';
import storeprofileReducer from './reducers/storeprofile.reducer';
import menufromeiosReducer from './reducers/menufromeios.reducer';
import customerReducer from './reducers/customer.reducer';
import { combineReducers } from 'redux';

export default combineReducers({
  auth: authReducer,
  user: userReducer,
  role: roleReducer,
  common: commonReducer,
  notifier: notifierReducer,
  status: statusReducer,
  lineoarichmenu: lineoarichmenuReducer,
  validatecode: validatecodeReducer,
  coupontype: coupontypeReducer,
  generatecouponcode: generatecouponcodeReducer,
  importcouponcode: importcouponcodeReducer,
  blacklist: blacklistReducer,
  userprofile: userprofileReducer,
  storeprofile: storeprofileReducer,
  menufromeios: menufromeiosReducer,
  customer: customerReducer,
});