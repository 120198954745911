import { lazy } from "react";
const UserProfile = lazy(() => import("./components/UserManagement/UserProfile"));
const StoreProfile = lazy(() => import("./components/StoreManagement/StoreProfile"));
const GenerateCouponCode = lazy(() => import("./components/CouponVoucherManagement/GenerateCouponCode"));
const ImportCouponCode = lazy(() => import("./components/CouponVoucherManagement/ImportCouponCode"));
const MenuFromEIOS = lazy(() => import("./components/SyncData/MenuFromEIOS"));
const TestMap = lazy(() => import("./components/TestMap"));

export const routes = [   
    {
        path: "/usermanagement/userprofile",
        exact: true,
        component: UserProfile,
        name: "userprofile"
    },
    {
        path: "/storemanagement/storeprofile",
        exact: true,
        component: StoreProfile,
        name: "storeprofile"
    },
    {
        path: "/generatecouponcode",
        exact: true,
        component: GenerateCouponCode,
        name: "generatecouponcode"
    },
    {
        path: "/importcouponcode",
        exact: true,
        component: ImportCouponCode,
        name: "importcouponcode"
    },    
    {
        path: "/syncdata/menufromeios",
        exact: true,
        component: MenuFromEIOS,
        name: "menufromeios"
    },
    {
        path: "/testmap",
        exact: true,
        component: TestMap,
        name: "testmap"
    }
];