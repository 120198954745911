import {
    REFRESHTABLE_STOREPROFILE,
    REFRESHTABLE_STOREPROFILE_AREA,
    SETDATA_STOREPROFILE,
    SETAREA_STOREPROFILE,
    SETORGANIZATION_STOREPROFILE,
    SETSTOREFORMAT_STOREPROFILE,
    SETPROVINCE_STOREPROFILE,
    DIALOG_STOREPROFILE_SYNC_CLEARFIELD,
    DIALOG_STOREPROFILE_SYNC,
    DIALOG_STOREPROFILE_SYNC_LOADING,
    DIALOG_STOREPROFILE_EDIT_CLEARFIELD,
    DIALOG_STOREPROFILE_EDIT,
    DIALOG_STOREPROFILE_EDIT_LOADING,
    DIALOG_STOREPROFILE_DELETE,
    DIALOG_STOREPROFILE_DELETE_LOADING,
    DIALOG_STOREPROFILE_ADD_AREA_CLEARFIELD,
    DIALOG_STOREPROFILE_ADD_AREA,
    DIALOG_STOREPROFILE_ADD_AREA_LOADING
} from "../constants/storeprofileConstants";

const initialState = {
    refreshtable: false,
    refreshtablearea: false,
    data: [],
    area: [],
    organization: [],
    storeformat: [],
    province: [],
    dialogsync: {
        claerfield: false,
        open: false,
        loading: false,
    },
    dialogedit: {
        claerfield: false,
        open: false,
        loading: false,
        data: null
    },
    dialogdelete: {
        open: false,
        loading: false,
        data: null
    },
    dialogarea: {
        claerfield: false,
        open: false,
        loading: false
    }
};

const userprofile = (state = initialState, action) => {
    switch (action.type) {
        case REFRESHTABLE_STOREPROFILE:
            let data = {}
            if (action.payload) {
                data = {
                    dialogsync: {
                        open: false,
                        loading: false
                    },
                    dialogedit: {
                        open: false,
                        loading: false,
                        data: null
                    },
                    dialogdelete: {
                        open: false,
                        loading: false,
                        data: null
                    },
                }
            };
            return {
                ...state,
                ...data,
                refreshtable: action.payload,
            };
        case REFRESHTABLE_STOREPROFILE_AREA:
            let area = {}
            if (action.payload) {
                area = {
                    dialogarea: {
                        open: false,
                        loading: false,
                        data: null
                    },
                }
            };
            return {
                ...state,
                ...area,
                refreshtablearea: action.payload,
            };
        case SETDATA_STOREPROFILE:
            return {
                ...state,
                data: action.payload,
            };
        case SETAREA_STOREPROFILE:
            return {
                ...state,
                area: action.payload,
                // dialogarea: {
                //     open: false,
                //     loading: false,
                //     data: null
                // },
                // refreshtablearea: true,
            };
        case SETORGANIZATION_STOREPROFILE:
            return {
                ...state,
                organization: action.payload,
            };
        case SETSTOREFORMAT_STOREPROFILE:
            return {
                ...state,
                storeformat: action.payload,
            };
        case SETPROVINCE_STOREPROFILE:
            return {
                ...state,
                province: action.payload,
            };
        case DIALOG_STOREPROFILE_SYNC_CLEARFIELD:
            return {
                ...state,
                dialogsync: {
                    ...state.dialogsync,
                    claerfield: action.payload,
                }
            }
        case DIALOG_STOREPROFILE_SYNC:
            return {
                ...state,
                dialogsync: action.payload,
            }
        case DIALOG_STOREPROFILE_SYNC_LOADING:
            return {
                ...state,
                dialogsync: {
                    ...state.dialogsync,
                    loading: action.payload,
                }
            };
        case DIALOG_STOREPROFILE_EDIT_CLEARFIELD:
            return {
                ...state,
                dialogedit: {
                    ...state.dialogedit,
                    claerfield: action.payload,
                }
            }
        case DIALOG_STOREPROFILE_EDIT:
            return {
                ...state,
                department: [],
                position: [],
                dialogedit: action.payload
            }
        case DIALOG_STOREPROFILE_EDIT_LOADING:
            return {
                ...state,
                dialogedit: {
                    ...state.dialogedit,
                    loading: action.payload,
                }
            };
        case DIALOG_STOREPROFILE_DELETE:
            return {
                ...state,
                dialogdelete: action.payload
            }
        case DIALOG_STOREPROFILE_DELETE_LOADING:
            return {
                ...state,
                dialogdelete: {
                    ...state.dialogdelete,
                    loading: action.payload,
                }
            };
        case DIALOG_STOREPROFILE_SYNC_CLEARFIELD:
            return {
                ...state,
                dialogsync: {
                    ...state.dialogsync,
                    claerfield: action.payload,
                }
            }
        case DIALOG_STOREPROFILE_ADD_AREA_CLEARFIELD:
            return {
                ...state,
                dialogarea: {
                    ...state.dialogarea,
                    claerfield: action.payload,
                }
            };
        case DIALOG_STOREPROFILE_ADD_AREA:
            return {
                ...state,
                dialogarea: action.payload
            }
        case DIALOG_STOREPROFILE_ADD_AREA_LOADING:
            return {
                ...state,
                dialogarea: {
                    ...state.dialogarea,
                    loading: action.payload,
                }
            };
        default:
            return state;
    }
};

export default userprofile;