import { 
  FETCH_CUSTOMER_REQUEST, FETCH_CUSTOMER_SUCCESS, FETCH_CUSTOMER_FAILURE,
  FETCH_POINTS_REQUEST, FETCH_POINTS_SUCCESS, FETCH_POINTS_FAILURE,
  FETCH_POINTS_LIST_REQUEST, FETCH_POINTS_LIST_SUCCESS, FETCH_POINTS_LIST_FAILURE,
  FETCH_NON_ORDER_LIST_REQUEST, FETCH_NON_ORDER_LIST_SUCCESS, FETCH_NON_ORDER_LIST_FAILURE,
  FETCH_TOPICS_REQUEST, FETCH_TOPICS_SUCCESS, FETCH_TOPICS_FAILURE,
  ADD_NON_ORDER_REQUEST, ADD_NON_ORDER_SUCCESS, ADD_NON_ORDER_FAILURE,
  FETCH_CUSTOMER_NON_ORDER_REQUEST, FETCH_CUSTOMER_NON_ORDER_SUCCESS, FETCH_CUSTOMER_NON_ORDER_FAILURE,
  EDIT_NON_ORDER_REQUEST, EDIT_NON_ORDER_SUCCESS, EDIT_NON_ORDER_FAILURE,
  DELETE_NON_ORDER_REQUEST, DELETE_NON_ORDER_SUCCESS, DELETE_NON_ORDER_FAILURE,
  FETCH_STORE_PROFILES_REQUEST, FETCH_STORE_PROFILES_SUCCESS, FETCH_STORE_PROFILES_FAILURE,
  ADD_NEW_MEMBER_REQUEST, ADD_NEW_MEMBER_SUCCESS, ADD_NEW_MEMBER_FAILURE,
  FETCH_PERMISSION_REQUEST, FETCH_PERMISSION_SUCCESS, FETCH_PERMISSION_FAILURE,
  GET_INFORMATION, GET_INFORMATION_ERROR, SET_NON_ORDER_LIST,
  UPDATE_NON_ORDER_ITEM, UPDATE_NON_ORDER_SUCCESS, UPDATE_NON_ORDER_ERROR, UPDATE_NON_ORDER_LIST,
  FETCH_NON_ORDER_START, ADD_NON_ORDER_OPTIMISTIC,
  UPDATE_OPTIMISTIC_NON_ORDER, REMOVE_OPTIMISTIC_NON_ORDER, SET_CUSTOMER_INFO,
  SET_PROFILE, SET_POINTS, SET_DETAIL_POINT,INSERT_POINTS_REQUEST, INSERT_POINTS_SUCCESS, INSERT_POINTS_FAILURE
} from '../constants/customerConstants';

const initialState = {
  data: null,
  points: null,
  pointsList: null,
  nonOrderList: [],
  topics: null,
  customerNonOrder: null,
  storeProfiles: null,
  permissions: null,
  profile: null,
  error: null,
  loading: false,
  insertPointsLoading: false,
  insertPointsError: null
};

const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CUSTOMER_REQUEST:
    case FETCH_POINTS_REQUEST:
    case FETCH_POINTS_LIST_REQUEST:
    case FETCH_NON_ORDER_LIST_REQUEST:
    case FETCH_TOPICS_REQUEST:
    case ADD_NON_ORDER_REQUEST:
    case FETCH_CUSTOMER_NON_ORDER_REQUEST:
    case EDIT_NON_ORDER_REQUEST:
    case DELETE_NON_ORDER_REQUEST:
    case FETCH_STORE_PROFILES_REQUEST:
    case ADD_NEW_MEMBER_REQUEST:
    case FETCH_PERMISSION_REQUEST:
    case FETCH_NON_ORDER_START:
      return {
        ...state,
        loading: true,
        error: null
      };

    case FETCH_CUSTOMER_SUCCESS:
    case GET_INFORMATION:
    case SET_CUSTOMER_INFO:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null
      };

    case FETCH_POINTS_SUCCESS:
    case SET_POINTS:
      return {
        ...state,
        loading: false,
        points: action.payload,
        error: null
      };

    case FETCH_POINTS_LIST_SUCCESS:
    case SET_DETAIL_POINT:
      return {
        ...state,
        loading: false,
        pointsList: action.payload,
        error: null
      };

    case FETCH_NON_ORDER_LIST_SUCCESS:
    case SET_NON_ORDER_LIST:
    case UPDATE_NON_ORDER_LIST:
      return {
        ...state,
        loading: false,
        nonOrderList: action.payload,
        error: null
      };

    case FETCH_TOPICS_SUCCESS:
      return {
        ...state,
        loading: false,
        topics: action.payload,
        error: null
      };

    case FETCH_CUSTOMER_NON_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        customerNonOrder: action.payload,
        error: null
      };

    case FETCH_STORE_PROFILES_SUCCESS:
      return {
        ...state,
        loading: false,
        storeProfiles: action.payload,
        error: null
      };

    case FETCH_PERMISSION_SUCCESS:
      return {
        ...state,
        loading: false,
        permissions: action.payload,
        error: null
      };

    case SET_PROFILE:
      return {
        ...state,
        profile: action.payload,
        error: null
      };

    case FETCH_CUSTOMER_FAILURE:
    case FETCH_POINTS_FAILURE:
    case FETCH_POINTS_LIST_FAILURE:
    case FETCH_NON_ORDER_LIST_FAILURE:
    case FETCH_TOPICS_FAILURE:
    case ADD_NON_ORDER_FAILURE:
    case FETCH_CUSTOMER_NON_ORDER_FAILURE:
    case EDIT_NON_ORDER_FAILURE:
    case DELETE_NON_ORDER_FAILURE:
    case FETCH_STORE_PROFILES_FAILURE:
    case ADD_NEW_MEMBER_FAILURE:
    case FETCH_PERMISSION_FAILURE:
    case GET_INFORMATION_ERROR:
    case UPDATE_NON_ORDER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    // These cases might need more specific handling based on your requirements
    case ADD_NON_ORDER_SUCCESS:
    case ADD_NON_ORDER_OPTIMISTIC:
    case EDIT_NON_ORDER_SUCCESS:
    case UPDATE_NON_ORDER_SUCCESS:
    case UPDATE_OPTIMISTIC_NON_ORDER:
    case DELETE_NON_ORDER_SUCCESS:
    case REMOVE_OPTIMISTIC_NON_ORDER:
    case ADD_NEW_MEMBER_SUCCESS:
    case UPDATE_NON_ORDER_ITEM:
      // You might want to update the nonOrderList or other relevant state here
      // For now, we'll just return the current state
      return state;
    case INSERT_POINTS_REQUEST:
      return {
        ...state,
        insertPointsLoading: true,
        insertPointsError: null
      };

    case INSERT_POINTS_SUCCESS:
      return {
        ...state,
        insertPointsLoading: false,
        insertPointsError: null
      };

    case INSERT_POINTS_FAILURE:
      return {
        ...state,
        insertPointsLoading: false,
        insertPointsError: action.error
      };

    default:
      return state;
  }
};

export default customerReducer;