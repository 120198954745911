import {
  SEARCH_LOADING,
  SET_OPSTIONS,
  SEATCH_DATA,
  REFRESHTABLE,
  SETDATA,
  DIALOGCONFIRMECODE,
  DIALOGCONFIRMECODE_LOADING,
  DIALOGCANCELCODE,
  DIALOGCANCELCODE_LOADING,
  SETSTORE_DIALOG
} from "../constants/validatecodeConstants";

const initialState = {
  searchloading: false,
  refreshtable: false,
  searchdata: {
    coupontype: "",
    couponcode: "",
  },
  optiondata: [],
  optionstore: [],
  data: [],
  dialogconfirmcode: {
    open: false,
    loading: false,
    id: "",
    couponcode: "",
  },
  dialogcancelcode: {
    open: false,
    loading: false,
    id: "",
    couponcode: "",
  },
};

const validatecode = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_LOADING:
      return {
        ...state,
        searchloading: action.payload,
      };
    case SET_OPSTIONS:
      return {
        ...state,
        optiondata: action.payload,
      }
    case SEATCH_DATA:     
      return {
        ...state,
        searchdata: action.payload,
      };
    case REFRESHTABLE:
      let data = {}
      if (action.payload) {
        data = {
          dialogconfirmcode: {
            open: false,
            loading: false,
            id: "",
            couponcode: "",
          },
          dialogcancelcode: {
            open: false,
            loading: false,
            id: "",
            couponcode: "",
          }
        }
      }

      return {
        ...state,
        ...data,
        refreshtable: action.payload,
      };
    case SETDATA:      
      return {
        ...state,
        data: action.payload,
      };
    case DIALOGCONFIRMECODE:
      return {
        ...state,
        dialogconfirmcode: action.payload,
      };
    case DIALOGCANCELCODE:
      return {
        ...state,
        dialogcancelcode: action.payload,
      };
    case DIALOGCONFIRMECODE_LOADING:
      return {
        ...state,
        dialogconfirmcode: {
          ...state.dialogconfirmcode,
          loading: action.payload,
        }
      }
    case SETSTORE_DIALOG:
      return {
        ...state,
        optionstore: action.payload,
      }
    case DIALOGCANCELCODE_LOADING:
      return {
        ...state,
        dialogcancelcode: {
          ...state.dialogcancelcode,
          loading: action.payload,
        }
      }
    default:
      return state;
  }
};

export default validatecode;