import { SET_USERS, SET_TOKEN, CLEAR_TOKEN } from "../constants/userConstants";

const initialState = {
  profile: {   
    user: "",
  },
  token: null,
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case SET_USERS:
      return {
        ...state,
        profile: action.payload,
      };
    case SET_TOKEN:
      return {
        ...state,
        token: action.payload,
      };
    case CLEAR_TOKEN:
      return {
        ...state,
        token: null,
      };
    default:
      return state;
  }
};

export default user;