import {
    SETSTATUS_DEFAULT,
    SETSTATUS_DEFAULTWITHDRAFT
} from "../constants/statusConstants";

const initialState = {
    statusdefault: [],
    statusdefaultwithdraft: []
};

const status = (state = initialState, action) => {
    switch (action.type) {
        case SETSTATUS_DEFAULT:
            return {
                ...state,
                statusdefault: action.payload,
            };
        case SETSTATUS_DEFAULTWITHDRAFT:
            return {
                ...state,
                statusdefaultwithdraft: action.payload,
            };
        default:
            return state;
    }
};

export default status;