import React, { Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes as Switch, useNavigate } from "react-router-dom";

import { authRoutes } from "./routes";
import { Layout } from "./common/layout";
import { Loading } from "./common/loading";
import { pageActions } from "./actions/common";

const AppRouter = () => {
  const commonState = useSelector((state) => state.common);
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const handleRedirect = (path) => {   
    navigate(path);
  };

  React.useEffect(() => {
    //Clear redireact page
    if (commonState.redirect.status)
      setTimeout(() => {
        dispatch(pageActions.clearRedireactPage());
      }, 500);

    if (commonState.redirect.status) {
      handleRedirect(commonState.redirect.path);
    }
  }, [commonState]);

  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        {authRoutes.map((route, idx) => {         
          return route.component ? (
            <Route
              key={idx}
              path={route.path}
              element={<route.component/>}            
            />
          ) : null;         
        })}
        <Route path="*" element={<Layout />} />
      </Switch>
    </Suspense>
  );
};

export default AppRouter;