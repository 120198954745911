import { lazy } from "react";
const SignIn = lazy(() => import("./components/SignIn"));
const ForgotPassword = lazy(() => import("./components/ForgotPassword"));

export const routes = [   
    {
        path: "/signin",
        exact: true,
        component: SignIn,
        name: "signin"
    },
    {
        path: "/forgotpassword",
        exact: true,
        component: ForgotPassword,
        name: "forgotpassword"
    }    
];