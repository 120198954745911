import { lazy } from "react";
const RichMenu = lazy(() => import("./components/LineOa/RichMenu"));
const BannerProfile = lazy(() => import("./components/LineOa/BannerProfile"));
const BannerProduct = lazy(() => import("./components/LineOa/BannerProduct"));
const Product = lazy(() => import("./components/LineOa/Product"));

export const routes = [
    {
        path: "/lineoa/richmenu",
        exact: true,
        component: RichMenu,
        name: "lineoa_richmenu"
    },
    {
        path: "/lineoa/bannerprofile",
        exact: true,
        component: BannerProfile,
        name: "lineoa_bannerprofile"
    },
    {
        path: "/lineoa/bannerproduct",
        exact: true,
        component: BannerProduct,
        name: "lineoa_bannerproduct"
    },
    {
        path: "/lineoa/product",
        exact: true,
        component: Product,
        name: "lineoa_product"
    }
];