import { format, parse, getTime, addDays as addDay } from "date-fns";

export function addDays(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export function convertToDatestamp(date) {
  let Result = null;
  try {
    Result = getTime(date)
  } catch (err) { };
  return Result;
};

export function parseStringToDate(date) {
  let Result = null;
  try {   
    Result = parse(date, 'yyyy-MM-dd HH:mm:ss', new Date());   
  } catch (err) { console.log(err)};
  return Result;
}

export function parseStringDateOnlySlashToDate(date) {
  let Result = null;
  try {       
    Result = parse(date, 'yyyy/MM/dd', new Date());       
  } catch (err) { console.log(err)};
  return Result;
}

export function parseStringLongToDate(date) {
  let Result = null;
  try {   
    Result = parse(date, 'yyyy-MM-dd HH:mm:ss.SSS', new Date());   
  } catch (err) { console.log(err)};
  return Result;
}

export function parseDateAddToDateStringOnly(date, add) {
  let Result = null;
  try {      
    const dateAdd = addDay(date, add);   
    Result = format(dateAdd, 'yyyy-MM-dd');   
  } catch (err) { console.log(err)};
  return Result;
}

export function  datestampToExportFileFormat(stamp) {
  let Date = null;
  try {
      Date = format(stamp, 'yyyyMMdd_HH:mm:ss.SSS')
  } catch (err) { };
  return Date;
};