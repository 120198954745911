import {
    DIALOG_CLEARDEFAULT_MENUFROMEIOS,
    REFRESHTABLE_MENUFROMEIOS,
    REFRESHTABLECATEGORY_MENUFROMEIOS,
    REFRESHTABLESIZE_MENUFROMEIOS,
    REFRESHTABLECRUST_MENUFROMEIOS,
    REFRESHTABLEPRODUCT_MENUFROMEIOS,
    REFRESHTABLEPROMOTION_MENUFROMEIOS,
    REFRESHTABLEINGREDIENT_MENUFROMEIOS,
    REFRESHTABLETIER_MENUFROMEIOS,
    SETDATA_MENUFROMEIOS,
    SETMENULIST_MENUFROMEIOS,
    SETDATACATEGORY_MENUFROMEIOS,
    SETDATASIZE_MENUFROMEIOS,
    SETDATACRUST_MENUFROMEIOS,
    SETDATAPRODUCT_MENUFROMEIOS,
    SETDATAPROMOTION_MENUFROMEIOS,
    SETDATAINGREDIENT_MENUFROMEIOS,
    SETDATATIER_MENUFROMEIOS,
    DIALOG_MENUFROMEIOS_ADD_CLEARFIELD,
    DIALOG_MENUFROMEIOS_ADD,
    DIALOG_MENUFROMEIOS_ADD_LOADING,
    DIALOG_MENUFROMEIOS_EDIT_CLEARFIELD,
    DIALOG_MENUFROMEIOS_EDIT,
    DIALOG_MENUFROMEIOS_EDIT_LOADING,
    DIALOG_MENUFROMEIOS_EDIT_CATEGORY_LOADING,
    DIALOG_MENUFROMEIOS_EDIT_SIZE_LOADING,
    DIALOG_MENUFROMEIOS_EDIT_CRUST_LOADING,
    DIALOG_MENUFROMEIOS_EDIT_PRODUCT_LOADING,
    DIALOG_MENUFROMEIOS_EDIT_PROMOTION_LOADING,
    DIALOG_MENUFROMEIOS_EDIT_INGREDIENT_LOADING,
    DIALOG_MENUFROMEIOS_EDIT_TIER_LOADING,
    DIALOG_MENUFROMEIOS_DELETE,
    DIALOG_MENUFROMEIOS_DELETE_LOADING
} from "../constants/menufromeiosConstants";

const initialState = {
    refreshtable: false,
    refreshtablecategory: false,
    refreshtablesize: false,
    refreshtablecrust: false,
    refreshtableproduct: false,
    refreshtablepromotion: false,
    refreshtableingredient: false,
    refreshtabletier: false,
    data: [],
    menulist: [],
    datacategory: [],
    datasize: [],
    datacrust: [],
    dataproduct: [],
    datapromotion: [],
    dataingredient: [],
    datatier: [],
    categoryloading: true,
    sizeloading: true,
    crustloading: true,
    productloading: true,
    promotionloading: true,
    ingredientloading: true,
    tierloading: true,
    dialogadd: {
        claerfield: false,
        open: false,
        loading: false,
    },
    dialogedit: {
        claerfield: false,
        open: false,
        loading: false,
        data: null
    },
    dialogeditcategory: {
        loading: false,
        data: null
    },
    dialogeditsize: {
        loading: false,
        data: null
    },
    dialogeditcrust: {
        loading: false,
        data: null
    },
    dialogeditproduct: {
        loading: false,
        data: null
    },
    dialogeditpromotion: {
        loading: false,
        data: null
    },
    dialogeditingredient: {
        loading: false,
        data: null
    },
    dialogedittier: {
        loading: false,
        data: null
    },
    dialogdelete: {
        open: false,
        loading: false,
        data: null
    }
};

const menufromeios = (state = initialState, action) => {
    switch (action.type) {
        case DIALOG_CLEARDEFAULT_MENUFROMEIOS:
            return {
                ...state,
                datacategory: [],
                categoryloading: true,
                datasize: [],
                sizeloading: true,
                datacrust: [],
                crustloading: true,
                dataproduct: [],
                productloading: true,
                datapromotion: [],
                promotionloading: true,
                dataingredient: [],
                ingredientloading: true,
                datatier: [],
                tierloading: true,
            }
        case REFRESHTABLE_MENUFROMEIOS:
            let data = {}
            if (action.payload) {
                data = {
                    ...data.dialogedit,
                    dialogadd: {
                        open: false,
                        loading: false
                    },
                    dialogdelete: {
                        open: false,
                        loading: false,
                        data: null
                    }
                }
            };
            return {
                ...state,
                ...data,
                refreshtable: action.payload,
            };
        case REFRESHTABLECATEGORY_MENUFROMEIOS:
            return {
                ...state,
                refreshtablecategory: action.payload,
                categoryloading: false
            };
        case REFRESHTABLESIZE_MENUFROMEIOS:
            return {
                ...state,
                refreshtablesize: action.payload,
                sizeloading: false
            };
        case REFRESHTABLECRUST_MENUFROMEIOS:
            return {
                ...state,
                refreshtablecrust: action.payload,
                crustloading: false
            };
        case REFRESHTABLEPRODUCT_MENUFROMEIOS:
            return {
                ...state,
                refreshtableproduct: action.payload,
                productloading: false
            };
        case REFRESHTABLEPROMOTION_MENUFROMEIOS:
            return {
                ...state,
                refreshtablepromotion: action.payload,
                promotionloading: false
            };
        case REFRESHTABLEINGREDIENT_MENUFROMEIOS:
            return {
                ...state,
                refreshtableingredient: action.payload,
                ingredientloading: false
            };
        case REFRESHTABLETIER_MENUFROMEIOS:
            return {
                ...state,
                refreshtabletier: action.payload,
                tierloading: false
            };
        case SETDATA_MENUFROMEIOS:
            return {
                ...state,
                data: action.payload,
            };
        case SETMENULIST_MENUFROMEIOS:
            return {
                ...state,
                menulist: action.payload,
            };
        case SETDATACATEGORY_MENUFROMEIOS:
            return {
                ...state,
                datacategory: action.payload,
            };
        case SETDATASIZE_MENUFROMEIOS:
            return {
                ...state,
                datasize: action.payload,
            };
        case SETDATACRUST_MENUFROMEIOS:
            return {
                ...state,
                datacrust: action.payload,
            };
        case SETDATAPRODUCT_MENUFROMEIOS:
            return {
                ...state,
                dataproduct: action.payload,
            };
        case SETDATAPROMOTION_MENUFROMEIOS:
            return {
                ...state,
                datapromotion: action.payload,
            };
        case SETDATAINGREDIENT_MENUFROMEIOS:
            return {
                ...state,
                dataingredient: action.payload,
            };
        case SETDATATIER_MENUFROMEIOS:
            return {
                ...state,
                datatier: action.payload,
            };
        case DIALOG_MENUFROMEIOS_ADD_CLEARFIELD:
            return {
                ...state,
                dialogadd: {
                    ...state.dialogadd,
                    claerfield: action.payload,
                }
            }
        case DIALOG_MENUFROMEIOS_ADD:
            return {
                ...state,
                dialogadd: action.payload,
            }
        case DIALOG_MENUFROMEIOS_ADD_LOADING:
            return {
                ...state,
                dialogadd: {
                    ...state.dialogadd,
                    loading: action.payload,
                }
            };
        case DIALOG_MENUFROMEIOS_EDIT_CLEARFIELD:
            return {
                ...state,
                dialogedit: {
                    ...state.dialogedit,
                    claerfield: action.payload,
                }
            }
        case DIALOG_MENUFROMEIOS_EDIT:
            return {
                ...state,
                department: [],
                position: [],
                dialogedit: action.payload
            }
        case DIALOG_MENUFROMEIOS_EDIT_LOADING:
            return {
                ...state,
                dialogedit: {
                    ...state.dialogedit,
                    loading: action.payload,
                }
            };
        case DIALOG_MENUFROMEIOS_EDIT_CATEGORY_LOADING:
            return {
                ...state,
                dialogeditcategory: action.payload
            };
        case DIALOG_MENUFROMEIOS_EDIT_SIZE_LOADING:
            return {
                ...state,
                dialogeditsize: action.payload
            };
        case DIALOG_MENUFROMEIOS_EDIT_CRUST_LOADING:
            return {
                ...state,
                dialogeditcrust: action.payload
            };
        case DIALOG_MENUFROMEIOS_EDIT_PRODUCT_LOADING:
            return {
                ...state,
                dialogeditproduct: action.payload
            };
        case DIALOG_MENUFROMEIOS_EDIT_PROMOTION_LOADING:
            return {
                ...state,
                dialogeditpromotion: action.payload
            };

        case DIALOG_MENUFROMEIOS_EDIT_INGREDIENT_LOADING:
            return {
                ...state,
                dialogeditingredient: action.payload
            };
        case DIALOG_MENUFROMEIOS_EDIT_TIER_LOADING:
            return {
                ...state,
                dialogedittier: action.payload
            };
        case DIALOG_MENUFROMEIOS_DELETE:
            return {
                ...state,
                dialogdelete: action.payload
            }
        case DIALOG_MENUFROMEIOS_DELETE_LOADING:
            return {
                ...state,
                dialogdelete: {
                    ...state.dialogdelete,
                    loading: action.payload,
                }
            };
        default:
            return state;
    }
};

export default menufromeios;