// Customer Information
export const FETCH_CUSTOMER_REQUEST = "FETCH_CUSTOMER_REQUEST";
export const FETCH_CUSTOMER_SUCCESS = "FETCH_CUSTOMER_SUCCESS";
export const FETCH_CUSTOMER_FAILURE = "FETCH_CUSTOMER_FAILURE";

// Points
export const FETCH_POINTS_REQUEST = "FETCH_POINTS_REQUEST";
export const FETCH_POINTS_SUCCESS = "FETCH_POINTS_SUCCESS";
export const FETCH_POINTS_FAILURE = "FETCH_POINTS_FAILURE";

// Points List
export const FETCH_POINTS_LIST_REQUEST = "FETCH_POINTS_LIST_REQUEST";
export const FETCH_POINTS_LIST_SUCCESS = "FETCH_POINTS_LIST_SUCCESS";
export const FETCH_POINTS_LIST_FAILURE = "FETCH_POINTS_LIST_FAILURE";

// Non-Order List
export const FETCH_NON_ORDER_LIST_REQUEST = "FETCH_NON_ORDER_LIST_REQUEST";
export const FETCH_NON_ORDER_LIST_SUCCESS = "FETCH_NON_ORDER_LIST_SUCCESS";
export const FETCH_NON_ORDER_LIST_FAILURE = "FETCH_NON_ORDER_LIST_FAILURE";

// Topics
export const FETCH_TOPICS_REQUEST = "FETCH_TOPICS_REQUEST";
export const FETCH_TOPICS_SUCCESS = "FETCH_TOPICS_SUCCESS";
export const FETCH_TOPICS_FAILURE = "FETCH_TOPICS_FAILURE";

// Add Non-Order
export const ADD_NON_ORDER_REQUEST = "ADD_NON_ORDER_REQUEST";
export const ADD_NON_ORDER_SUCCESS = "ADD_NON_ORDER_SUCCESS";
export const ADD_NON_ORDER_FAILURE = "ADD_NON_ORDER_FAILURE";

// Customer Non-Order Information
export const FETCH_CUSTOMER_NON_ORDER_REQUEST = "FETCH_CUSTOMER_NON_ORDER_REQUEST";
export const FETCH_CUSTOMER_NON_ORDER_SUCCESS = "FETCH_CUSTOMER_NON_ORDER_SUCCESS";
export const FETCH_CUSTOMER_NON_ORDER_FAILURE = "FETCH_CUSTOMER_NON_ORDER_FAILURE";

// Edit Non-Order
export const EDIT_NON_ORDER_REQUEST = "EDIT_NON_ORDER_REQUEST";
export const EDIT_NON_ORDER_SUCCESS = "EDIT_NON_ORDER_SUCCESS";
export const EDIT_NON_ORDER_FAILURE = "EDIT_NON_ORDER_FAILURE";

// Delete Non-Order
export const DELETE_NON_ORDER_REQUEST = "DELETE_NON_ORDER_REQUEST";
export const DELETE_NON_ORDER_SUCCESS = "DELETE_NON_ORDER_SUCCESS";
export const DELETE_NON_ORDER_FAILURE = "DELETE_NON_ORDER_FAILURE";

// Store Profiles
export const FETCH_STORE_PROFILES_REQUEST = "FETCH_STORE_PROFILES_REQUEST";
export const FETCH_STORE_PROFILES_SUCCESS = "FETCH_STORE_PROFILES_SUCCESS";
export const FETCH_STORE_PROFILES_FAILURE = "FETCH_STORE_PROFILES_FAILURE";

// Add New Member
export const ADD_NEW_MEMBER_REQUEST = "ADD_NEW_MEMBER_REQUEST";
export const ADD_NEW_MEMBER_SUCCESS = "ADD_NEW_MEMBER_SUCCESS";
export const ADD_NEW_MEMBER_FAILURE = "ADD_NEW_MEMBER_FAILURE";

// Permissions
export const FETCH_PERMISSION_REQUEST = "FETCH_PERMISSION_REQUEST";
export const FETCH_PERMISSION_SUCCESS = "FETCH_PERMISSION_SUCCESS";
export const FETCH_PERMISSION_FAILURE = "FETCH_PERMISSION_FAILURE";

// CalculatePoint
export const INSERT_POINTS_REQUEST = "INSERT_POINTS_REQUEST";
export const INSERT_POINTS_SUCCESS = "INSERT_POINTS_SUCCESS";
export const INSERT_POINTS_FAILURE = "INSERT_POINTS_FAILURE";


// Existing constants
export const GET_INFORMATION = "GET_INFORMATION";
export const GET_INFORMATION_ERROR = "GET_INFORMATION_ERROR";
export const GET_NON_ORDER_LIST_ERROR = "GET_NON_ORDER_LIST_ERROR";
export const ADD_NON_ORDER_ERROR = "ADD_NON_ORDER_ERROR";
export const SET_NON_ORDER_LIST = "SET_NON_ORDER_LIST";
export const UPDATE_NON_ORDER_ITEM = "UPDATE_NON_ORDER_ITEM";
export const UPDATE_NON_ORDER_SUCCESS = "UPDATE_NON_ORDER_SUCCESS";
export const UPDATE_NON_ORDER_ERROR = "UPDATE_NON_ORDER_ERROR";
export const UPDATE_NON_ORDER_LIST = "UPDATE_NON_ORDER_LIST";
export const FETCH_NON_ORDER_START = "FETCH_NON_ORDER_START";
export const ADD_NON_ORDER_OPTIMISTIC = "ADD_NON_ORDER_OPTIMISTIC";
export const UPDATE_OPTIMISTIC_NON_ORDER = "UPDATE_OPTIMISTIC_NON_ORDER";
export const REMOVE_OPTIMISTIC_NON_ORDER = "REMOVE_OPTIMISTIC_NON_ORDER";
export const SET_CUSTOMER_INFO = "SET_CUSTOMER_INFO";
export const SET_PROFILE = "SET_PROFILE";
export const SET_POINTS = "SET_POINTS";
export const SET_DETAIL_POINT = "SET_DETAIL_POINT";